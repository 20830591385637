<template>
  <div class="profile-page _font-weight__600">
    <div class="profile-page__title _font-size__heading3">Edit Profile</div>

    <div class="form-box">
      <el-form
        :rules="rules"
        :model="formData"
        label-width="250px"
        label-position="left"
        size="medium"
        ref="form"
      >
        <el-form-item :label="item.label" :prop="item.key" v-for="item in formView" :key="item.key">
          <div
            class="form-box__content _flex"
            :style="{ background: item.disabled ? '' : 'var(--color-white)' }"
          >
            <el-input v-model="formData[item.key]" :maxlength="64" v-if="!item.disabled" />
            <span v-else>{{ formData[item.key] }}</span>
          </div>
        </el-form-item>
      </el-form>

      <div class="form-box__buttons">
        <el-button type="primary" plain @click="$router.back()">Cancel</el-button>
        <el-button type="primary" @click="handleSubmit">Save</el-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scope>
.profile-page {
  &__title {
    margin-bottom: 40px;
  }

  .form-box {
    .el-form-item {
      margin-bottom: 35px;
      > label {
        height: 44px;
        line-height: 44px;
        color: var(--color-black);
      }
    }
    .form-box__content {
      width: 375px;
      padding-left: 15px;
      .el-input {
        width: 100%;

        &__inner {
          height: 44px;
          line-height: 44px;
          border: none;
          border-bottom: 1px solid var(--color-info-light-7);
          border-radius: 0;
          padding: 0;
          color: var(--color-black);

          &::placeholder {
            color: var(--color-black);
          }
        }
      }
    }

    &__buttons {
      margin-top: 80px;
      .el-button {
        width: 160px;
        font-weight: 600;
        font-size: 14px;
      }
      .el-button + .el-button {
        margin-left: 45px;
      }
    }
  }
}
</style>

<script>
import Cookies from 'js-cookie'
import { mapState } from 'vuex'
import { debounce } from '@/utils/debounce-throttle'
import { checkMailRegistered, setUserInfo } from '@/api/auth'
import { Encrypt } from '@/utils/ASE-secret'
import { emailRules } from '../login/components/index'

export default {
  data() {
    return {
      formView: [
        {
          label: 'Username',
          key: 'nickName'
        },
        {
          label: 'Email',
          key: 'email',
          disabled: true
        },
        {
          label: 'Company Name',
          key: 'companyName'
          // disabled: true
        }
      ],
      formData: {
        nickName: '',
        email: '',
        companyName: ''
      },
      rules: {
        nickName: { required: true, message: 'Please enter username', trigger: ['change', 'blur'] },
        email: [
          ...emailRules,
          {
            type: 'email',
            validator: (rule, value, callback) => {
              if (value === this.userInfo.email) {
                callback()
              } else {
                debounce(() => {
                  checkMailRegistered(value).then((res) => {
                    if (res.result)
                      callback('ID already exists, you can try logging in with this ID.')
                    else callback()
                  })
                })
              }
            }
          }
        ]
      }
    }
  },
  computed: mapState({
    userInfo: (state) => state.user.userInfo
  }),
  activated() {
    this.formData = {
      nickName: this.userInfo.nickName,
      email: this.userInfo.email,
      companyName: this.userInfo.companyName
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          setUserInfo({
            nickName: this.formData.nickName,
            email: this.formData.email,
            companyName: this.formData.companyName
          }).then(() => {
            if (this.formData.email !== this.userInfo.email) {
              this.$message.success('修改成功，邮箱地址发生改变，请重新登录！')
              Cookies.remove('tower-token')
              Cookies.remove('email')
              Cookies.set('username', this.formData.nickName)
              localStorage.setItem('isRemember', false)
              setTimeout(() => {
                this.$router.replace('/login')
              }, 200)

              return
            }

            this.$message.success('修改成功！')
            Cookies.set('username', Encrypt(this.formData.nickName))
            this.$store.dispatch('getUserInfo')
          })
        }
      })
    }
  }
}
</script>
